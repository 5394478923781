import React from "react";
import { Link } from "react-router-dom";
import './Card_unused.css';

const Card = ({ EventData }) => {

  // Helper function to format the price
  const formatPrice = (price) => {
    if (price === '0.00') return 'Free';
    const num = parseFloat(price);
    if (Number.isInteger(num)) {
      return `£${num}`;
    }
    return `£${num.toFixed(2)}`;
  };

  return (
    <Link to={`/event/${EventData.event_id}`} key={EventData.event_id}>
      <div
        className="
        card-container
        flex
        flex-col
        flex-shrink-0
        flex-grow-0
        min-w-[150px]
        sm:min-w-[200px]
        md:min-w-[250px]
        lg:min-w-[300px]
        max-w-[100%]
        shadow-md
        bg-white
        rounded-md
        hover:shadow-lg
        hover:scale-105
        transition
        duration-200
        overflow-hidden
      "
      >
        <div
          className="card-image relative w-full aspect-video bg-cover bg-center"
          style={{ backgroundImage: `url(${EventData?.thumbnail_path})` }}
        />
        <div className="card-body h-full flex flex-col px-4 pb-3 pt-0">
          <p className="font-poppins text-xl text-center font-bold mt-3">
            {EventData.event_name}
          </p>
          <p className="font-poppins text-sm text-center text-gray-600 mt-2">
            {EventData.start_date} at{' '}
            {EventData.start_time && EventData.start_time.split(':').slice(0, 2).join(':')}
          </p>
          <p className="font-poppins text-sm text-center text-gray-600 mt-1">
            {EventData.venue_name || "Venue Name"}
          </p>
          <p className="font-poppins text-base text-center font-bold mt-2">
            {formatPrice(EventData.ticket_price)}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
