// src/components/pages/Home.jsx

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import CardSlider from "../../component/Events/CardSlider";
import { fetchGetItems } from "../../redux/createEvent/createEventAction/GetEventAction";
import { eventTypeChoices } from "../../assets/static/eventTypes";
import HomeHelmet from "../../component/MetaHelmet/HomeHelmet";
import MiddleBanner from "./BannerComponents/MiddleBanner";
import SlickBanner from "./BannerComponents/SlickBanner";
import { getFilteredEvents } from "../../redux/auth/action/filterSelector";

const Home = () => {
  const dispatch = useDispatch();

  let renderedCount = 0;

  const today = new Date().setHours(0, 0, 0, 0);

  // Access the events data from Redux store
  const eventData = useSelector((state) => state.getEvent);
  const { data, loading, error } = eventData;

  // Get filters from Redux store
  const filters = useSelector((state) => state.filters);

  const filtersApplied = Object.values(filters).some(
    (value) => value !== "" && value !== false
  );

  // Get filtered events using selector
  const filteredData = useSelector(getFilteredEvents);

  // Fetch all events when the component mounts
  useEffect(() => {
    dispatch(fetchGetItems());    
  }, [dispatch]);

  // ------------------- New Listings Code -------------------

  // Calculate the date three days ago from today
  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  // Filter events created in the last 3 days
  const newListings = data
    ? data.filter((event) => {
      const eventDate = new Date(event.created_at);
      const endDate = new Date(event.end_date).setHours(0, 0, 0, 0);
      return eventDate >= threeDaysAgo && endDate >= today;
    })
    : [];

  // -------------------- New Listings Code Ends Here --------------------

  return (
    <div className="min-h-screen flex flex-col font-poppins bg-[#f2f4f7]">
      <HomeHelmet />
      {/* Navbar with filtering capabilities is now accessible on all pages */}
      <Navbar />

      {/* Banner */}
      <SlickBanner events={data} />

      {/* Main Content */}
      <main className="flex-grow">
        {/* Container for max-width and centered alignment */}
        <div className="max-w-[1200px] mx-auto px-4">
          {filtersApplied && filteredData !== data ? (
            <>
              <p className="font-poppins text-lg font-semibold mt-7">
                SEARCH RESULTS
              </p>
              {filteredData.length > 0 ? (
                <CardSlider data={filteredData} />
              ) : (
                <p className="mt-10 mb-20 text-3xl text-gray-500 opacity-60 text-center">
                  <i>No events match your filters.</i>
                </p>
              )}
            </>
          ) : (
            <>
              {/* New Listings Section */}
              {newListings.length > 0 && (
                <div>
                  <p
                    className="font-poppins text-lg font-semibold mt-7 uppercase cursor-pointer inline-block"
                    title="Events added in the last 3 days"
                  >
                    New Listings
                  </p>
                  <CardSlider data={newListings} />
                </div>
              )}

              {/* Dynamic Event Type Sections */}
              {eventTypeChoices.map((type) => {
                const eventsOfType = data
                  ? data.filter(
                    (event) => event.event_segment === type.key
                  )?.filter(event => new Date(event.end_date).setHours(0, 0, 0, 0) >= today)
                  : [];
                if (eventsOfType.length === 0) return null;

                renderedCount++;

                return (
                  <div key={type.key}>
                    <p className="font-poppins text-lg font-semibold mt-7 uppercase">
                      {type.label}
                    </p>
                    <CardSlider
                      className="relative"
                      data={eventsOfType}
                      loading={loading}
                    />

                    {/* Add the component after rendering half of the non-empty sections */}
                    {renderedCount ===
                      Math.ceil(
                        eventTypeChoices.filter((type) =>
                          data?.some(
                            (event) => event.event_segment === type.key
                          )
                        ).length / 2
                      ) && <MiddleBanner />}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
