// Base setup for the future

import React from 'react'
import { FaApple, FaGooglePlay } from 'react-icons/fa'

function MiddleBanner() {
  return (
    <div className="w-full h-42 relative flex justify-between items-center rounded-lg mb-6 mx-auto" style={{ backgroundColor: '#FED7B6' }}>
        
            <div className='flex text-[#072436] p-6 mx-auto text-2xl font-bold'>Eventfull Sundays</div>
            <div className="flex flex-col gap-4 p-6 mx-auto">
                <p className="text-[#072436] font-poppins text-base tracking-wider font-semibold text-center md:text-left">
                    Download our app
                </p>
                <div className="flex justify-center md:justify-start gap-4">
                    <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                        <FaGooglePlay size={30} className="text-[#EA6F33] hover:text-[#FFFFFF] transition-colors duration-200" />
                    </a>
                    <a href="https://apple.com/app-store" target="_blank" rel="noopener noreferrer">
                        <FaApple size={30} className="text-[#EA6F33] hover:text-[#FFFFFF] transition-colors duration-200" />
                    </a>
                </div>
            </div>
      
    </div>
  )
}

export default MiddleBanner
