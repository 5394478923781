import React, { useState } from "react";
import user from "../../assets/images/user.png";
import logo from '../../assets/images/eventfullsunday.png';
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IoPersonCircle,
  IoEyeOutline,
  IoEyeOffOutline,
  IoCallOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/auth/action/RegisterAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import ReactGA from "react-ga4";

import LeftSection from '../../component/LeftSection';
import { FcGoogle } from "react-icons/fc";
import { useLogin } from "../googleAuth";
import PhoneInput from "react-phone-number-input";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
  };

  const login = useLogin();
  
  const handleRegister = (values, { setSubmitting }) => {
    dispatch(registerUser(values))
      .then(() => {
        
        // Fire GA event for successful registration
        ReactGA.event({
          category: 'User',
          action: 'Register',
          label: values.email, // Ensure no PII is sent. Consider using a user role or similar non-PII identifier.
        });

      })
      .catch((error) => {
        // Optionally, show an error toast
        console.log("Error: ", error);

      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Phone number must be only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits")
      .required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left Section */}
      <LeftSection
        logo={logo}
        mainImage={user}
      />

      {/* Right Section: Register Form */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8 fade-in-bottom">
        <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              confirm_password: "",
              phone_number: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="w-full">
                <p className="text-2xl font-semibold text-center mb-6">Register</p>
                <div className="mb-4">
                  <label htmlFor="first_name" className="block mb-2 text-sm font-medium">First Name</label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="Joshuwa"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                    />
                  </div>
                  <ErrorMessage name="first_name" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div className="mb-4">
                  <label htmlFor="last_name" className="block mb-2 text-sm font-medium">Last Name</label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Mashon"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                    />
                  </div>
                  <ErrorMessage name="last_name" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                  <div className="relative">
                    <MdOutlineMailOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type="email"
                      name="email"
                      placeholder="joshuwamason@gmail.com"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                    />
                  </div>
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div className="mb-4">
                  <label htmlFor="phone_number" className="block mb-2 text-sm font-medium">Phone Number</label>
                  <div className="relative flex items-center">
                    {/*<IoCallOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <span className="absolute left-10 top-1/2 transform -translate-y-1/2 text-gray-500">+44</span>
                    <Field
                      type="tel"
                      name="phone_number"
                      placeholder="   075********"
                      className="w-full p-3 pl-16 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                      style={{ paddingLeft: '70px' }}
                    />*/}
                    <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="GB"
                    value={values.phone_number}
                    international
                    countryCallingCodeEditable={false}
                    onChange={phone => setFieldValue('phone_number', phone)}
                    className="w-full p-3 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]" />
                  </div>
                  <ErrorMessage name="phone_number" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                <div className="mb-4">
                  <label htmlFor="password" className="block mb-2 text-sm font-medium">Password</label>
                  <div className="relative">
                    <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Iampassword123"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showPassword ? (
                        <IoEyeOffOutline className="w-5 h-5" />
                      ) : (
                        <IoEyeOutline className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div className="mb-4">
                  <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium">Confirm Password</label>
                  <div className="relative">
                    <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type={confirmPassword ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Iampassword123"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                    />
                    <button
                      type="button"
                      onClick={toggleConfirmPasswordVisibility}
                      className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {confirmPassword ? (
                        <IoEyeOffOutline className="w-5 h-5" />
                      ) : (
                        <IoEyeOutline className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  <ErrorMessage name="confirm_password" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div className="flex justify-center mb-4">
                  <button type="submit" className="w-full p-3 bg-sunday_primary text-white rounded-full hover:brightness-110 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-600">
                    Register
                  </button>
                </div>
                {/* Divider */}
                <div className="flex items-center my-4">
                  <hr className="flex-grow border-t border-gray-300" />
                  <span className="mx-2 text-gray-500">Or</span>
                  <hr className="flex-grow border-t border-gray-300" />
                </div>

                {/* Google Login Button */}
                <div className="flex justify-center mb-4">
                  <button
                    type="button"
                    onClick={() => login()}
                    className="flex items-center justify-center w-full p-3 bg-gray-100 text-black rounded-full hover:bg-gray-200 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-600"
                    style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.25)" }}
                  >
                    <FcGoogle size={25} className="mr-2" />
                    Sign Up with Google
                  </button>
                </div>
                <div className="text-center">
                  <p className="text-sm">
                    Already have an account? <Link to="/login" className="text-[#1399FF] underline">Login Here</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer position="top-right" autoClose={5000} />
        </div>
      </div>
    </div>
  );
};

export default Register;
