// src/components/pages/EventDetails.jsx

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { EventGetItems } from "../../redux/auth/action/eventDetailAction";
import { getUserProfile } from "../../redux/auth/action/userProfileActions";
import { fetchTicketByEventId } from "../../redux/auth/action/fetchTicketActions"; // Adjust the path as necessary
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import { toast, ToastContainer } from "react-toastify";
import { FaTicketAlt, FaCalendarAlt } from "react-icons/fa";
import Shimmer from "../../component/Shimmer";
import "./EventDetails.css";
import { handleAddToCalendar } from "../../component/Events/addToCalendar";
import ReactGA from "react-ga4";
import VenueTime from "./VenueTime";
import LocationSection from "./LocationSection";
import TicketSection from "./TicketSection";
import { sanitizeDescription } from "./sanitizeDescription";

const EventDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access") || sessionStorage.getItem("access");

  // Access data from Redux store
  const { loading: eventItemsLoading, data, error: eventError } = useSelector((state) => state.eventItems);
  const { loading: profileLoading, profile, error: profileError } = useSelector((state) => state.profile);
  const { loading: fetchTicketLoading, tickets, error: fetchTicketError } = useSelector((state) => state.fetchTicket);
  const selectedTicket = useSelector((state) => state.selectedTicket.selectedTicket);
  const selectedQuantity = useSelector((state) => state.selectedTicket.ticketQuantity); // Access ticketQuantity from Redux
  const { is_verified } = profile || {};

  // Ref for Tickets Section
  const ticketsSectionRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));                           // Fetch event details
      if (accessToken) {
        dispatch(getUserProfile());                          // Fetch user profile if access token exists
      }
      dispatch(fetchTicketByEventId(id));                    // Fetch ticket information related to the event
      console.log("tickets in eventdetails: ", tickets);
    }
  }, [accessToken, dispatch, id]);

  // Fire GA Event when event data is loaded
  useEffect(() => {
    if (data && data.event_name) {
      ReactGA.event({
        category: 'Event',
        action: 'View Event Details',
        label: data.event_name, // Ensure no PII is included
      });

      console.log(`GA Event Sent: View Event Details for "${data.event_name}"`);       // Optionally, log to console for verification
    }
  }, [data]);

  const defaultImage = "https://via.placeholder.com/300x200.png?text=Default+Image";

  const handleGetTicketsClick = () => {
    if (fetchTicketLoading) {
      if (!toast.isActive(2, "eventDetails")) {
        toast.info("Checking ticket availability. Please wait...");
      }
      return;
    }

    if (profileLoading) {
      if (!toast.isActive(3, "eventDetails")) {
        toast.info("Loading your profile information. Please wait...");
      }
      return;
    }

    if (data?.external_url) {
      window.open(data.external_url, "_blank", "noopener,noreferrer");
      return;
    }

    if (tickets && tickets.length > 0 && !selectedTicket) {
      ticketsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (!accessToken) {
      if (!toast.isActive(4, "eventDetails")) {
        toast.error("You need to be logged in to purchase tickets.");
      }
      return;
    }

    if (accessToken && typeof is_verified === "undefined") {
      if (!toast.isActive(5, "eventDetails")) {
        toast.error("Failed to load profile information. Please try again.");
      }
      return;
    }

    if (accessToken && is_verified === false) {
      if (!toast.isActive(6, "eventDetails")) {
        toast.error(
          <>
            <p>You must be verified before you can purchase tickets.</p>
            <p>Please check your email for a link to verify your account.</p>
          </>
        );
      }
      return;
    }

    // If the user is verified and tickets are selected, navigate to /readyfortickets
    if (accessToken && is_verified === true) {
      if (selectedTicket && selectedQuantity > 0) {
        // Navigate to /readyfortickets without passing state
        navigate("/readyfortickets");
      } else {
        // No tickets selected, show toast notification
        if (!toast.isActive(7, "eventDetails")) {
          toast.error("Please select a ticket and quantity to proceed.");
        }
      }
    }
  };

  // Add logging for selectedTicket
  useEffect(() => {
    console.log("Selected Ticket in EventDetails:", selectedTicket);
  }, [selectedTicket]);

  return (
    <div className="flex flex-col min-h-screen bg-[#f2f4f7]">
      <Navbar />

      <ToastContainer position="top-right" containerId={"eventDetails"} autoClose={3000} />
      <div className="flex-grow p-5 flex justify-center">
        <div className="relative w-full max-w-5xl flex flex-col font-poppins">

          {/* Thumbnail Image */}
          {eventItemsLoading && (
            <div className="relative w-full aspect-16/9 overflow-hidden mb-4">
              <Shimmer width="100%" height="500px" borderRadius="rounded-xl" />
            </div>
          )}
          {!eventItemsLoading && (
            <div
              className="relative rounded-xl overflow-hidden mb-4 w-full"
              style={{ paddingBottom: '56.25%' }}
            >
              <img
                src={data?.thumbnail_path || defaultImage}
                alt="Event Thumbnail"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          )}

          {/* Short date */}
          <VenueTime eventItemsLoading={eventItemsLoading} data={data} short={true} />

          {/* Event Name */}
          {eventItemsLoading && (
            <Shimmer width="60%" height="24px" borderRadius="rounded" className="mb-3" />
          )}
          {!eventItemsLoading && (
            <h1 className="text-5xl font-bold mb-3">{data?.event_name || "Untitled Event"}</h1>
          )}

          {/* Event Category/s */}
          <div className="flex items-center w-max mb-3 py-2 text-white bg-sunday_secondary bg-opacity-85 rounded-full">
            {eventItemsLoading && <Shimmer width="90px" height="35px" borderRadius="rounded-full" />}
            {(!eventItemsLoading && data?.category_name) && (
              <div
              className={`${data.subcategory_name ? "ps-4" : "px-4"} text-sm`}
              >
                {data.category_name}
              </div>
            )}

            <span className="mx-2 h-[20px]">·</span>

            {(!eventItemsLoading && !data?.category_name) && (
              <p><i>No categories available</i></p>
            )}

            {/* Event Subcategory/s */}
            {eventItemsLoading && <Shimmer width="90px" height="35px" borderRadius="rounded-full" />}
            {!eventItemsLoading && data?.subcategory_name ? (
              <div className="pe-4 text-sm"
              >
                {data.subcategory_name}
              </div>
            ) : (
              null
            )
            }
          </div>

          {/* Short description test */}
          <p className="mb-1 text-sm">
            This is a short description that will be visible above the get tickets button and below the category and sub-category. Lorem ipsum dolor sit amet.
          </p>

          {/* Ticket and Calendar Options */}
          {eventItemsLoading ? (
            <div className="flex flex-col sm:flex-row gap-4">
              <Shimmer width="100%" height="48px" borderRadius="rounded-full" />
              <Shimmer width="100%" height="48px" borderRadius="rounded-full" />
            </div>
          ) : (
            <div className="flex flex-col py-3 bg-[#f2f4f7] z-20 sm:flex-row gap-4 sticky top-0">
              <button
                className="bg-sunday_primary scaling-button w-full max-w-full sm:max-w-xs flex items-center justify-center text-white hover:brightness-95 transition duration-200 py-2 rounded-full"
                onClick={handleGetTicketsClick}
                disabled={!(data?.external_url || (tickets && tickets.length > 0))}
              >
                {selectedTicket?.ticket_category?.toLowerCase() === "free"
                  ? "Check out for Free"
                  : selectedTicket
                    ? `Check out for £${(selectedTicket.price * selectedQuantity).toFixed(2)}`
                    : "Get Tickets"
                } <FaTicketAlt className="ms-2" />
              </button>
              <button
                title="Add the event to your Google Calendar"
                className="bg-transparent scaling-button w-full max-w-full sm:max-w-xs flex items-center justify-center text-sunday_primary border-2 border-sunday_primary hover:brightness-105 transition duration-200 py-2 rounded-full"
                onClick={() => {
                  if (!data) {
                    if (!toast.isActive(1, "eventDetails")) {
                      toast.error("Event data is unavailable.");
                    }
                    return;
                  }
                  handleAddToCalendar(data)
                }
                }
                disabled={eventItemsLoading || fetchTicketLoading || !data}
                data-tip="Add this event to your Google Calendar"
              >
                Add to Calendar
              </button>
            </div>
          )}

          {/* Organizer company (only shows when event type is internal) */}

          {/* Divider */}
          {/* <div className={data?.event_type === "External" ? "h-[1px] w-full mx-auto mt-4 bg-gray-200" : "h-[1px] w-full mx-auto my-4 bg-gray-200"}></div> */}

          {data?.event_type === "External" ? (null) : (
            <div className="mt-5 px-4 py-3 w-max bg-gray-200 rounded-md flex justify-start items-center opacity-80 text-sm font-poppins">

              <div className="w-14 h-14 rounded-full overflow-hidden flex items-center justify-center">
                <img
                  className="object-cover w-full h-full"
                  src="https://lh7-rt.googleusercontent.com/docsz/AD_4nXcTC9Lk1v7Bd5PbcjqlDEKiiqqovqH9SUki4i55_CMzQXZFUKy9plPMdtyksxxfkwv2_EUhhgazPdmjSAMozpdlfYZlpWKQI9n5ehPqwgZlmKU6kz8A5cKaPRuiMt10pQE8T8m9u-_RbVZD2Ac=s412?key=Fns8aJ4mVHSejBZWZKfxdQ"
                  alt="Company logo"
                />
              </div>

              <div>
                <span className="ms-2 me-1">By</span>
                {eventItemsLoading ? (
                  <Shimmer width="50%" height="20px" borderRadius="rounded" />
                ) : (
                  <span className="font-semibold">
                    {data?.organizer_company ? data.organizer_company : <i>No information</i>}
                  </span>
                )}
              </div>
            </div>)}

          {/* Long date and time */}
          <div>
            <p className="text-xl font-semibold mt-7 mb-1">Date and Time</p>
            <VenueTime eventItemsLoading={eventItemsLoading} data={data} short={false} />
          </div>

          {/* Event Details */}
          <div className="mt-7">
            <p className="text-xl font-semibold mb-2">About this event</p>
            {eventItemsLoading ? (
              <div className="space-y-4">
                <Shimmer width="100%" height="16px" borderRadius="rounded" />
                <Shimmer width="90%" height="16px" borderRadius="rounded" />
                <Shimmer width="95%" height="16px" borderRadius="rounded" />
                <Shimmer width="80%" height="16px" borderRadius="rounded" />
              </div>
            ) : (
              <>
                <VenueTime eventItemsLoading={eventItemsLoading} data={data} duration={true} />

                <div
                  className={`prose event-description text-black leading-relaxed space-y-4 ${isExpanded ? "max-h-full" : "max-h-40 overflow-hidden"
                    } md:max-h-full`}
                  dangerouslySetInnerHTML={{ __html: sanitizeDescription(data?.description) }}
                />
                {/* "Read more" button only on mobile */}
                <button
                  className="text-blue-500 hover:underline md:hidden"
                  onClick={toggleReadMore}
                >
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              </>
            )}

          </div>

          {/* Location Section */}
          {eventItemsLoading ? (
            <div className="mt-7 space-y-4">
              <Shimmer width="30%" height="24px" borderRadius="rounded" />
              <Shimmer width="100%" height="200px" borderRadius="rounded" />
              <div className="flex justify-around w-full md:w-[550px]">
                {[...Array(4)].map((_, index) => <Shimmer key={index} width="60px" height="60px" borderRadius="rounded-full" />)}
              </div>
            </div>
          ) : (
            <LocationSection data={data} />
          )}

          {/* Tickets Section */}
          {data?.event_type === "External" ? (
            null
          ) : (
            <div ref={ticketsSectionRef} className={data?.extra_image || data?.extra_image2 ? "mt-7" : "mt-7 mb-5"}>
              <p className="text-xl font-semibold mb-4">Tickets</p>
              {fetchTicketLoading ? (
                <div className="space-y-4">
                  {[...Array(3)].map((_, index) => (<Shimmer key={index} width="100%" height="80px" borderRadius="rounded-lg" />))}
                </div>
              ) : tickets && tickets.length > 0 ? (
                <TicketSection
                  tickets={tickets}
                // Remove the following props as TicketSelection manages state via Redux
                // selectedTicket={selectedTicket}
                // selectedQuantity={selectedQuantity}
                // setSelectedTicket={(ticket) => dispatch(setSelectedTicket(ticket))}
                // setSelectedQuantity={(quantity) => dispatch(setSelectedQuantity(quantity))}
                />
              ) : (
                <p>No tickets available for this event.</p>
              )}
            </div>
          )}

          {/* Additional Images */}
          {eventItemsLoading ? (
            <div className="mt-8">
              <p className="text-xl font-semibold mb-2">Additional Images</p>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Shimmer width="100%" height="200px" borderRadius="rounded-md" />
                <Shimmer width="100%" height="200px" borderRadius="rounded-md" />
              </div>
            </div>
          ) : [data?.extra_image, data?.extra_image2].some(Boolean) && (
            <div className="mt-8">
              <p className="text-xl font-semibold mb-2">Additional Images</p>

              <div className="flex flex-col sm:flex-row gap-4">
                {[data?.extra_image, data?.extra_image2].map((image, index) =>
                  image ? (
                    <img
                      key={index}
                      src={image || defaultImage}
                      alt={`Additional ${index + 1}`}
                      className="rounded-md w-full h-auto max-w-[300px] max-h-[200px] object-cover" />
                  ) : null)}
              </div>
            </div>
          )}

        </div>
      </div >

      <Footer />
    </div >
  );
};

export default EventDetails;
