// src/components/pages/UserLogin.jsx

import React, { useState, useEffect } from "react";
import user from "../../assets/images/user.png"; // Unique image for User End
import logo from '../../assets/images/eventfullsunday.png'; // Unique logo for User End
import Google from "../../assets/images/Google.png"; // Unique Google image if needed

import { Field, Form, Formik } from "formik";
import {
  IoPersonCircle,
  IoEyeOutline,
  IoEyeOffOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/auth/action/LoginAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeftSection from '../../component/LeftSection'; // Unique LeftSection for User End
import { FcGoogle } from "react-icons/fc";
import ReactGA from "react-ga4";
import { useLogin } from "../googleAuth";

const UserLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  // Access login state from Redux
  const { loading, isAuthenticated, error } = useSelector((state) => state.login);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  // Initial form values
  const initialValues = {
    email: "",
    password: "",
    remember: false,
  };

  // Google Login Handler
  const login = useLogin();

  // Form validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required"),
  });

  // Handle form submission
  const handleLogin = async (values, { setSubmitting }) => {
    try {
      await dispatch(loginUser(values, values.remember)); // Dispatch the login action

      // Fire GA event for successful login
      ReactGA.event({
        category: 'User',
        action: 'Login',
      });

      // Success toast will be shown in the action or handled here
    } catch (error) {
      // Since loginUser handles errors and toasts, no need to handle here
    } finally {
      setSubmitting(false);
    }
  };

  // Use useEffect to handle redirect on successful login
  useEffect(() => {
    if (isAuthenticated) {
      // Navigate to home page
      window.location.href = "/"; // Alternatively, use a navigation library like useNavigate
      toast.success("Logged in successfully!");
    }
  }, [isAuthenticated]);

  // Optional: useEffect to handle errors if needed
  useEffect(() => {
    if (error) {
      // Errors are already handled via toasts in the action
      // Additional error handling can be implemented here if needed
    }
  }, [error]);

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left Section */}
      <LeftSection
        logo={logo} // Unique logo prop
        mainImage={user} // Unique main image prop
      />

      {/* Right Section: Login Form */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8 fade-in-bottom">
        <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {({ handleSubmit, handleBlur, handleChange, values, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit} className="w-full">
                {/* Form Title */}
                <p className="text-2xl font-semibold text-center mb-6">Login</p>

                {/* Email/Phone Number Field */}
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium">Email/Phone Number</label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      placeholder="nepalieventorg@gmail.com"
                      className={`w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 ${errors.email && touched.email ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                        }`}
                    />
                  </div>
                  {/* Error Message */}
                  {errors.email && touched.email && (
                    <div className="text-red-500 text-sm mt-1">{errors.email}</div>
                  )}
                </div>

                {/* Password Field */}
                <div className="mb-4">
                  <label htmlFor="password" className="block mb-2 text-sm font-medium">Password</label>
                  <div className="relative">
                    <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      placeholder="••••••••"
                      className={`w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 ${errors.password && touched.password ? 'border-red-500 focus:ring-red-500' : 'border-blue-600 focus:ring-blue-600'
                        }`}
                    />
                    {/* Toggle Password Visibility */}
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                    >
                      {!showPassword ? (
                        <IoEyeOffOutline className="w-5 h-5" />
                      ) : (
                        <IoEyeOutline className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  {/* Error Message */}
                  {errors.password && touched.password && (
                    <div className="text-red-500 text-sm mt-1">{errors.password}</div>
                  )}
                </div>

                {/* Remember Me & Forgot Password */}
                <div className="flex justify-between items-center mb-4">
                  {/* Remember Me */}
                  <label className="flex items-center">
                    <Field type="checkbox" name="remember" className="mr-2" />
                    Remember Me
                  </label>
                  {/* Forgot Password */}
                  <Link to="/ForgotPassword" className="text-blue-600 text-sm underline">Forgot Password?</Link>
                </div>

                {/* Login Button */}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="w-full p-3 mt-2 bg-sunday_primary text-white rounded-full hover:brightness-110 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-600"
                    disabled={isSubmitting || loading}
                  >
                    Login
                  </button>
                </div>

                {/* Divider */}
                <div className="flex items-center my-4">
                  <hr className="flex-grow border-t border-gray-300" />
                  <span className="mx-2 text-gray-500">Or</span>
                  <hr className="flex-grow border-t border-gray-300" />
                </div>

                {/* Google Login Button */}
                <div className="flex justify-center mb-4">
                  <button
                    type="button"
                    onClick={() => login()}
                    className="flex items-center justify-center w-full p-3 bg-gray-100 text-black rounded-full hover:bg-gray-200 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-600"
                    style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.25)" }}
                  >
                    <FcGoogle size={25} className="mr-2" />
                    Sign In with Google
                  </button>
                </div>

                {/* Register Link */}
                <div className="text-center">
                  <p className="text-sm">
                    Don’t have an account? <Link to="/register" className="text-blue-600 underline">Register Here</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer position="top-right" autoClose={5000} />
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
