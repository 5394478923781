import apiClient from "../../../apiClient/apiClient";
import { GET_WISHLIST_REQUEST, GET_WISHLIST_SUCCESS, GET_WISHLIST_FAILURE } from "../constant/fetchWishlistConstant";

export const getWishlist = () => async(dispatch) => {
    dispatch({
        type: GET_WISHLIST_REQUEST
    });

    try {

        const token = localStorage.getItem("access") || sessionStorage.getItem("access");
        const config = {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        };

        if(token) {
            const response = await apiClient('/wishlist/list', config);
            dispatch({
                type: GET_WISHLIST_SUCCESS,
                payload: response.data
            })
        } else {
            throw new Error("Error fetching Wishlist");
        }
        
    } catch (error) {
        dispatch({
            type: GET_WISHLIST_FAILURE,
            payload: {
                message: 'Error fetching Wishlist'
            }
        })
    }
}