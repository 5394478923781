// src/redux/auth/reducer/EventDetailReducer.jsx

import {
  GET_CREATE_EVENT_DETAILS_FAILURE,
  GET_CREATE_EVENT_DETAILS_REQUEST,
  GET_CREATE_EVENT_DETAILS_SUCCESS,
  RESET_EVENT_DETAILS, // Import the action type
} from "../constant/EventDetailConstant";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const eventDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREATE_EVENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Clear previous errors
      };
    case GET_CREATE_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_CREATE_EVENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_EVENT_DETAILS:
      return initialState; // Reset to initial state
    default:
      return state;
  }
};
