export const navAnchorLinks = [
    {
        link: "https://organizer.eventfullsunday.co.uk/",
        target: "",
        rel: "",
        title: "CREATE EVENT"
    },
    {
        link: "https://events.eventfullsunday.co.uk/",
        target: "_blank",
        rel: "noopener noreferrer",
        title: "BLOGS"
    }
];

export const navRouterLinks = [
    {
        link: "/register",
        title: "SIGNUP"
    },
    {
        link: "/login",
        title: "LOGIN"
    }
];