// src/components/pages/readyfortickets/step2-ReviewAndPayment.jsx

import React, { useState } from "react";
import axios from "axios";
import { CiLocationOn, CiClock2 } from "react-icons/ci";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { resetSelectedTicket } from "../../redux/auth/action/selectedTicketActions";
import { getUserTicketByTicketId } from "../../redux/auth/action/userTicketActions"; // Ensure this action is correctly defined
import apiClient from "../../apiClient/apiClient";

const ReviewAndPayment = ({ eventDetails, handleConfirmPayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [pageLoading, setPageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const selectedTicket = useSelector((state) => state.selectedTicket.selectedTicket);
  const { userTicket, loadingUserTicket, error: userTicketError } = useSelector((state) => state.singleUserTicket);

  if (!selectedTicket) {
    return <p className="text-red-500">No ticket selected. Please go back and select a ticket.</p>;
  }

  const handlePayment = async () => {
    if (!selectedTicket) {
      setErrorMessage("Ticket data is not loaded yet. Please wait.");
      return;
    }

    setPageLoading(true);

    try {
      const accessToken = localStorage.getItem("access") || sessionStorage.getItem("access");

      if (!accessToken) {
        setErrorMessage("Authentication accessToken not found. Please log in.");
        setPageLoading(false);
        return;
      }

      if (!stripe || !elements) {
        setErrorMessage("Stripe has not been properly initialized.");
        setPageLoading(false);
        return;
      }

      const formData = {
        event: selectedTicket.event, // event ID
        ticket: selectedTicket.id,
        ticket_quantity: eventDetails.ticket_quantity,
        sub_total: eventDetails.sub_total,
        total: eventDetails.total,
      };

      console.log("[DEBUG] Form data: ", formData);

      const response = await apiClient.post("api/ticket-purchase/", formData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const clientSecret = response.data.client_secret;

      if (!clientSecret) {
        toast.success("Free ticket confirmed!", {
          toastId: 1,
        });

        dispatch(getUserTicketByTicketId(selectedTicket.id));
        handleConfirmPayment();
        setPageLoading(false);

        return;
      }

      const cardElement = elements.getElement(CardElement);
      const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: eventDetails?.event_name || "Unnamed Event",
          },
        },
      });

      if (paymentError) {
        setErrorMessage(paymentError.message);
        setPageLoading(false);
        return;
      }

      if (paymentIntent && paymentIntent.status === "succeeded") {
        toast.success("Payment confirmed! Your booking is successful.");
        // Fetch the user ticket
        dispatch(getUserTicketByTicketId(eventDetails.event));
        handleConfirmPayment();
        setPageLoading(false);

        // Fire Google Analytics event for paid tickets
        ReactGA.event('purchase', {
          currency: 'GBP',
          value: eventDetails.total,
          items: [
            {
              item_id: selectedTicket.id,
              item_name: eventDetails.event_name,
              price: eventDetails.price,
              quantity: eventDetails.ticket_quantity,
            },
          ],
        });

        console.log(`GA Event Sent: Paid Purchase for "${eventDetails.event_name}" with value £${eventDetails.total}`);
        
      } else {
        setErrorMessage("Payment could not be confirmed. Please try again.");
      }
    } catch (error) {
      console.error("Error confirming payment", error);
      setErrorMessage("Payment failed. Please try again.");
    }

    setPageLoading(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const isTicketDataLoaded = selectedTicket && !pageLoading;

  console.log("Event details @ step 2: ", eventDetails);

  return (
    <div className="shadow-lg bg-gray-100 rounded-lg">
      <div
        className="p-3 rounded-t-lg"
        style={{
          background: "linear-gradient(90deg, rgba(235,140,88,1) 0%, rgba(252,222,194,1) 50%, rgba(235,140,88,1) 100%)",
        }}
      >
        <h2 className="font-poppins text-lg md:text-xl text-center text-[#0A2533]">Checkout</h2>
      </div>
      <div className="flex flex-col md:flex-row md:justify-evenly items-center p-9 rounded-b-lg">
        {/* INFO CONTAINER */}
        <div className="event-info w-full md:w-[35%] ps-4 text-gray-700 grid grid-cols-1 font-poppins">
          {/* MAIN INFO */}
          <div className="pb-1 text-lg">
            <p className="text-3xl font-semibold mb-1 text-black">{eventDetails.event_name || "Unknown Event"}</p>
            <p>
              {eventDetails.venue ? `${eventDetails.venue},` : "Unknown Venue"}, {eventDetails.country || "Unknown Country"}
            </p>
            <p>{eventDetails.address || ""}</p>
            <p>{eventDetails.post_code || ""}</p>
            <p>{eventDetails.time || "Unknown Time"}</p>
            <p>{eventDetails.start_date || "Unknown Date"}</p>
          </div>

          {/* DIVIDER */}
          <div className="h-[1px] bg-gray-200 w-[50%]"></div>

          {/* EXTRA INFO */}
          <div className="pt-1">
            <div className="py-2">
              <p>Ticket Quantity: {eventDetails.ticket_quantity || 1}</p>
              <p>Price per Ticket: £{eventDetails.price?.toFixed(2) || 0}</p>
              <p>
                Ticket Type:{" "}
                {selectedTicket?.ticket_category
                  ? selectedTicket.ticket_category.charAt(0).toUpperCase() + selectedTicket.ticket_category.slice(1)
                  : "Unknown Ticket"}
              </p>
              <p className="text-base">Subtotal: £{eventDetails.sub_total?.toFixed(2) || 0}</p>
            </div>

            {/* DIVIDER */}
            <div className="h-[1px] bg-gray-200 w-[50%]"></div>

            {/* TOTALS */}
            <div className="py-2">
              <p className="text-2xl mt-2">
                <strong>Total:</strong> £{eventDetails.total?.toFixed(2) || 0}
              </p>
            </div>
          </div>
        </div>

        {/* PURCHASE INFO CONTAINER */}
        <div className="flex flex-col justify-center w-full md:w-[45%]">
          <div className="mt-4">
            <CardElement options={cardElementOptions} />
          </div>

          {errorMessage && <div className="text-red-500 mt-4 text-center">{errorMessage}</div>}

          <div className="flex justify-center mt-6">
            <button
              className="bg-[rgba(235,140,88,1)] text-white py-2 px-4 rounded-lg transition duration-200 hover:brightness-125 w-[250px] md:w-full"
              onClick={handlePayment}
              disabled={!stripe || pageLoading || !isTicketDataLoaded}
            >
              {pageLoading ? "Processing..." : "Place Order"}
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ReviewAndPayment;
