import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile, updateUserProfile, sendResetVerificationEmail } from "../../../redux/auth/action/userProfileActions";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { VscUnverified, VscVerified } from "react-icons/vsc";
import userImage from "../../../assets/images/user.png";
import ProfileUpdateModal from "./ProfileUpdateModal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const resetVerificationState = useSelector((state) => state.resetVerification);
  const [hasDisplayedToast, setHasDisplayedToast] = useState(true);
  const navigate = useNavigate();

  // Fetch profile data
  useEffect(() => {
    dispatch(getUserProfile());

    let timer;
    if (isCooldown) {
      timer = setTimeout(() => {
        setIsCooldown(false);
      }, 10000); // 10 seconds cooldown
    }
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [dispatch, isCooldown]);

  // Handle verification email toast
  useEffect(() => {
    if (
      resetVerificationState.data &&
      !resetVerificationState.loading &&
      !hasDisplayedToast
    ) {
      console.log(resetVerificationState.data);

      if (!toast.isActive(1, "profileComponent")) {
        toast.success(
          "You've successfully requested an account verification email!", {
          toastId: 1
        }
        );
      }

      setHasDisplayedToast(true);
    }
    if (
      resetVerificationState.error &&
      !hasDisplayedToast
    ) {
      if (!toast.isActive(2, "profileComponent")) {
        toast.error(
          "There was a problem with sending a verification email. Please try again later.", {
          toastId: 2
        }
        );
      }
      setHasDisplayedToast(true);
    }
  }, [
    resetVerificationState.data,
    resetVerificationState.error,
    resetVerificationState.loading,
    hasDisplayedToast,
  ]);

  if (!localStorage.getItem("access") && !sessionStorage.getItem("access")) {
    navigate("/login"); // Navigate to login page if there's no logged in profile
  }

  if (!profileState.profile) {
    return <div>Loading...</div>;
  }

  const {
    first_name,
    last_name,
    email,
    phone_number,
    profile_picture,
    is_verified
  } = profileState.profile || {};

  const initialValues = {
    first_name,
    last_name,
    email,
    phone_number: phone_number ?? "",
    password: "",
    confirm_password: "",
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle image file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {

      // Create FormData object to hold the profile data
      const formData = new FormData();
      formData.append('first_name', first_name);
      formData.append('last_name', last_name);
      formData.append('email', email);
      formData.append('phone_number', phone_number);
      formData.append('profile_picture', file);

      console.log(formData);

      dispatch(updateUserProfile(formData));
    }
  };

  // Function to resend verification email
  const handleResendVerificationEmail = () => {
    dispatch(sendResetVerificationEmail(email));
    setHasDisplayedToast(false);
    setIsCooldown(true);
  };

  const getVerifiedStatus = () => {
    if (profileState.loading) {
      return (
        <span className="text-gray-500">Loading...</span>
      );
    } else {
      if (is_verified && is_verified === true) {
        console.log("verified!!");

        return (
          <span className="flex">
            <span className="text-sunday_success">Verified</span> <VscVerified size={20} className="ms-1 my-[2px] text-sunday_success" />
          </span>
        );
      } else if (is_verified === false) {
        console.log("not verified!!");

        return (
          <>
            <button
              className="flex items-center"
              onClick={handleResendVerificationEmail}
              disabled={isCooldown}
              title="Click to resend a verification link to your email"
            >
              <span
                className={`flex items-center mx-auto underline transition duration-200 ${isCooldown
                  ? "text-gray-500 no-underline opacity-80"
                  : "text-sunday_error hover:brightness-110"
                  }`}
              >
                <span>Unverified</span>
                <VscUnverified size={20} className="ms-1 my-[2px]" />
              </span>
            </button>
            {isCooldown && (
              <span className="text-red-500 text-xs mt-1">
                You can request a new email in 10 seconds.
              </span>
            )}
          </>
        );
      }
    }
  };

  return (
    <div className="flex justify-center sm:justify-start p-4 sm:p-0 bg-gray-100">
      <ToastContainer position="top-right" containerId="profileComponent" autoClose={3000} />

      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <div className="flex flex-col items-center relative">
          {/* Profile picture with clickable upload and edit icon */}
          <label htmlFor="profile-pic-upload" className="relative w-24 h-24 cursor-pointer">
            {/* The image with hover darken effect */}
            <img
              src={profile_picture || userImage}
              alt="User"
              className="w-24 h-24 rounded-full mb-4 object-cover hover:brightness-90 transition-all duration-200"
            />
            {/* The edit icon */}
            <div className="flex justify-center items-center absolute bottom-0 right-0 bg-sunday_primary rounded-full w-[25px] h-[25px]">
              <FiEdit2 size={15} className="text-white" />
            </div>
            {/* Hidden file input */}
            <input
              id="profile-pic-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleFileChange}
            />
          </label>

          <h2 className="text-lg font-semibold">{`${first_name} ${last_name}`}</h2>
          <p className="text-gray-500 mb-2">{email}</p>
          <p className="text-gray-500 mb-4 flex flex-col justify-center align-center">{getVerifiedStatus()}</p>

          {/* Profile Buttons */}
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-x-2">
            <button
              onClick={() => setIsModalOpen(true)}
              className="w-40 p-2 bg-sunday_primary text-white rounded-full hover:brightness-95 transition duration-200"
            >
              Update
            </button>
            <button
              onClick={() => navigate("/forgotpassword")}
              className="w-40 p-2 bg-white border border-sunday_tertiary text-sunday_tertiary hover:brightness-95 transition duration-200 rounded-full"
            >
              Change Password
            </button>
            {/* <button
              onClick={() => (handleResendVerificationEmail)}
              className="w-1/3 p-2 bg-gray-500 text-white rounded-full"
              disabled={isCooldown} // Disable button during cooldown
            >
              Send Verification Email
            </button> */}
          </div>

        </div>
      </div>
      <ProfileUpdateModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        initialValues={initialValues}
      />
    </div>
  );
};

export default Profile;
