import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetItems } from "../../redux/createEvent/createEventAction/GetEventAction";
import Navbar from "../Navbar/Navbar";
import Card from "../../component/Events/CardComponent/Card";
import Footer from "../Footer";

const Music = () => {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.getEvent);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const categoryId = 1;

  useEffect(() => {
    dispatch(fetchGetItems());
  }, [dispatch]);

  const { data, loading, error } = categoryState;

  useEffect(() => {
    if (data && data.length > 0) {
      const filtered = data.filter((event) => event.category_name === 'Music');
      setFilteredEvents(filtered);
    }
  }, [data, categoryId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="bg-[#f2f4f7]">
      <Navbar />
      <div className="flex justify-center py-8">
        <div className="w-[85vw] flex flex-col gap-6">
          <h2 className="font-poppins text-2xl tracking-wide font-semibold text-center text-[#333] mb-6">
            Music Events
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {filteredEvents.map((EventData, index) => (
              <Card EventData={EventData} index={index} key={EventData.event_id} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Music;
