import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaHistory,
  FaBell,
  FaLock,
  FaCog,
  FaSignOutAlt,
  FaArrowLeft,
  FaHeart
} from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import Profile from "./Profile/Profile";
import PurchaseHistory from "./PurchaseDetails/PurchaseHistory";
import Updates from "./Updates";
import PrivacyPolicy from "./PrivacyPolicy";
import Settings from "./Settings";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../assets/Button/Button";
import Wishlist from "./Wishlist";

const Dashboard = () => {
  const [selectedSection, setSelectedSection] = useState("profile");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");
    if (
      section &&
      [
        "profile",
        "purchase-history",
        "wishlist",
        "updates",
        "privacy-policy",
        "settings",
      ].includes(section)
    ) {
      setSelectedSection(section);
    }
  }, [location.search]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
      if (window.innerWidth >= 640) {
        setIsSidebarVisible(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderContent = () => {
    switch (selectedSection) {
      case "profile":
        return <Profile />;
      case "purchase-history":
        return <PurchaseHistory />;
      case "wishlist":
        return <Wishlist />;
      case "updates":
        return <Updates />;
      case "privacy-policy":
        return <PrivacyPolicy />;
      case "settings":
        return <Settings />;
      default:
        return <Profile />;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    console.log("All tokens cleared from storage");
    navigate("/login");
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <div className="flex flex-grow flex-col-reverse sm:flex-row bg-gray-100">
        {/* Sidebar */}
        {isSidebarVisible && (
          <div className="w-full sm:w-[350px] bg-white shadow-lg p-4 md:p-8 h-auto sm:h-full lg:h-auto">
            <div className="flex flex-col h-full">
              <div>
                <Button
                  sidebar
                  onClick={() => {
                    setSelectedSection("profile");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  selected={selectedSection === "profile"}
                  sidebarSelected="text-white bg-sunday_primary rounded-lg"
                  sidebarDefault="text-sunday_info hover:bg-gray-200 rounded-lg"
                >
                  <FaUser className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Profile</span>
                </Button>
                <Button
                  sidebar
                  onClick={() => {
                    setSelectedSection("purchase-history");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  selected={selectedSection === "purchase-history"}
                  sidebarSelected="text-white bg-sunday_primary rounded-lg"
                  sidebarDefault="text-sunday_info hover:bg-gray-200 rounded-lg"
                >
                  <FaHistory className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Purchase History</span>
                </Button>
                <Button
                  sidebar
                  onClick={() => {
                    setSelectedSection("wishlist");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  selected={selectedSection === "wishlist"}
                  sidebarSelected="text-white bg-sunday_primary rounded-lg"
                  sidebarDefault="text-sunday_info hover:bg-gray-200 rounded-lg"
                >
                  <FaHeart className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Wishlist</span>
                </Button>
                <Button
                  sidebar
                  onClick={() => {
                    setSelectedSection("updates");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  selected={selectedSection === "updates"}
                  sidebarSelected="text-white bg-sunday_primary rounded-lg"
                  sidebarDefault="text-sunday_info hover:bg-gray-200 rounded-lg"
                >
                  <FaBell className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Updates</span>
                </Button>
                <Button
                  sidebar
                  onClick={() => {
                    setSelectedSection("privacy-policy");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  selected={selectedSection === "privacy-policy"}
                  sidebarSelected="text-white bg-sunday_primary rounded-lg"
                  sidebarDefault="text-sunday_info hover:bg-gray-200 rounded-lg"
                >
                  <FaLock className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Privacy Policy</span>
                </Button>
                <Button
                  sidebar
                  onClick={() => {
                    setSelectedSection("settings");
                    if (isMobile) setIsSidebarVisible(false);
                  }}
                  selected={selectedSection === "settings"}
                  sidebarSelected="text-white bg-sunday_primary rounded-lg"
                  sidebarDefault="text-sunday_info hover:bg-gray-200 rounded-lg"
                >
                  <FaCog className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Settings</span>
                </Button>
                <Button
                  sidebar
                  selected={false}
                  onClick={handleLogout}
                  sidebarSelected="text-white bg-red-500 rounded-lg"
                  sidebarDefault="text-sunday_info transition duration-300 ease-in-out w-full text-sunday_info bg-white rounded-lg hover:bg-sunday_secondary"
                >
                  <FaSignOutAlt className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                  <span>Logout</span>
                </Button>
              </div>

              <div className="text-center mt-8">
                <p className="text-sm text-gray-600">
                  &copy; 2022 eventfullsundays.co.uk
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div
          className={`w-full ${
            isSidebarVisible ? "sm:w-3/5 lg:w-4/5 xl:w-3/4" : ""
          } p-4 md:p-8`}
        >
          {isMobile && !isSidebarVisible && (
            <button
              onClick={() => {
                setIsSidebarVisible(true);
                setSelectedSection("profile");
              }}
              className="flex items-center mt-2 ms-2 mb-4 text-xl text-sunday_info hover:text-sunday_primary duration-100 transition"
            >
              <FaArrowLeft className="mr-2" /> Back
            </button>
          )}
          {renderContent()}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
