import React, { useEffect, useState } from 'react'
import CardSlider from '../../component/Events/CardSlider';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetItems } from '../../redux/createEvent/createEventAction/GetEventAction';
import {getWishlist} from '../../redux/auth/action/fetchWishlist';

function Wishlist() {

  const allEvents = useSelector(state => state.getEvent);
  const dispatch = useDispatch();

  const wishlist = useSelector(state => state.wishlist);
  const [favEvents, setFavEvents] = useState();

  useEffect(() => {
    dispatch(fetchGetItems());
  }, [dispatch])

  useEffect(() => {
    dispatch(getWishlist())
  }, [dispatch])

  useEffect(() => {
    if(allEvents && wishlist?.wishlist?.length > 0) {
      const favEventIds = wishlist?.wishlist?.map(item => item.event); // Get event IDs from wishlist
      const filteredEvents = allEvents.data?.filter(event => favEventIds.includes(event.event_id));
      setFavEvents(filteredEvents);
    }
  }, [allEvents, wishlist])

  return (
    <>
      <CardSlider data={favEvents} />
    </>
  )
}

export default Wishlist
