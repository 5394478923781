import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Login from "./auth/Login/Login";
import { Route, Routes } from "react-router-dom";
import Register from "./auth/Register/Register";
import ForgotPassword from "./auth/Password/ForgotPassword";
import ResetPassword from "./auth/Password/ResetPassword";
import ForgotPasswordConfirm from "./auth/Password/ForgotPasswordConfirm.jsx"
import EmailConfirmation from "./auth/Register/EmailConfirmation.jsx";
import SuccessfulVerification from "./auth/Login/SuccessfulVerification.jsx";
import Arts from "./page/Category/Arts.jsx";
import Business from "./page/Category/Business.jsx";
import Shows from "./page/Category/Shows.jsx";
import Sports from "./page/Category/Sports.jsx";
import Music from "./page/Category/Music.jsx";
import Tech from "./page/Category/Tech.jsx";
import Home from "./page/Home/Home.jsx";
import EventDetails from "./page/EventDetail/EventDetails";
import Footer from "./page/Footer";
import Dashboard from "./page/Dashboard/dashboard.jsx";
import ReadyforTickets from "./component/ticketComponent/step0-readyfortickets";
import PurchaseHistory from "./page/Dashboard/PurchaseDetails/PurchaseHistory.jsx";

import ReactGA from "react-ga4";

function App() {
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Initialize Google Analytics with Measurement ID and enable debug mode
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, { debug: true });

    // Send the initial page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    // Optional: Log to console that GA has been initialized
    console.log(`GA Initialized: Pageview sent for ${window.location.pathname}`);
  }, []); // Only run once on mount


  useEffect(() => {
    // Send page view on route change
    ReactGA.send({ hitType: "pageview", page: location.pathname });

    // Log to console that a pageview has been sent
    console.log(`GA Pageview sent for ${location.pathname}`);
  }, [location]); // Run this effect whenever the location changes

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/forgotPassword" element={<ForgotPassword />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route exact path="/passwordResetConfirmation" element={<ForgotPasswordConfirm />} />
      <Route exact path="/emailConfirmation" element={<EmailConfirmation />} />
      <Route exact path="/activate/:uid/:token" element={<SuccessfulVerification />} />
      <Route exact path="/music" element={<Music />} />
      <Route exact path="/tech" element={<Tech />} />
      <Route exact path="/arts" element={<Arts />} />
      <Route exact path="/business" element={<Business />} />
      <Route exact path="/shows" element={<Shows />} />
      <Route exact path="/sports" element={<Sports />} />
      <Route exact path="/" element={<Home />} />
      <Route exact path="/footer" element={<Footer />} />
      <Route exact path="/event/:id" element={<EventDetails />} />
      <Route exact path="/dashboard" element={<Dashboard />} />
      <Route exact path="/purchase-history" element={<PurchaseHistory />} />
      <Route exact path='/readyfortickets' element={<ReadyforTickets />} />
    </Routes>
  );
}

export default App;
