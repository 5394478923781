import React from 'react'
import { motion } from "framer-motion";
import { NavLink } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import ProfileLinks from './ProfileLinks';
import AuthLinks from './AuthLinks';

function MobileMenu({ categories, loading, firstName}) {

  return (
    <motion.div
        className="md:hidden bg-white w-full shadow-md z-20 origin-top overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: "auto" }}
        exit={{ height: 0 }}
        transition={{ duration: 0.3 }}
    >
        <div className="flex flex-wrap justify-center gap-2 mt-2">
            {categories.map((category) => (
                <NavLink
                    key={category}
                    to={`/${category.category_name}`}
                    className="text-gray-500 px-4 py-2 text-center rounded-full border-2 border-sunday_secondary text-sm font-poppins hover:bg-blue-100 hover:text-blue-600 transition duration-200"
                >
                    {category.category_name.charAt(0).toUpperCase() + category.category_name.slice(1)}
                </NavLink>
            ))}
            <button
                onClick={() => window.open("https://maps.google.com", "_blank")}
                className="text-gray-500 px-4 py-2 text-center rounded-full border-2 border-sunday_secondary text-sm font-poppins flex items-center justify-center hover:bg-blue-100 hover:text-blue-600 transition duration-200"
            >
                <IoLocationSharp className="mr-1" /> Search Nearby Location
            </button>
        </div>
        <div className="flex flex-col items-center gap-4 mt-4 mb-3">
            {localStorage.getItem("access") || sessionStorage.getItem("access")
            ? <ProfileLinks loading={loading} firstName={firstName} />
            : <AuthLinks />}
        </div>
    </motion.div>
  )
}

export default MobileMenu
