import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import "./slickBanner.css";
import Shimmer from '../../../component/Shimmer';
import apiClient from '../../../apiClient/apiClient';

function SlickBanner({ events }) {

    const [ images, setImages ] = useState();
    const [loading, setLoading] = useState(true);
    const [eventId, setEventId] = useState();

    const ctaButtons = [
        {
            text: "Explore Event",
            url: `/event/${eventId ?? events[0]?.event_id}`
        },
        {
            text: "Get tickets",
            url: `/event/${events[1]?.event_id ?? events[0]?.event_id}`
        },
        {
            text: 'Music events',
            url: `/music`
        },
        {
            text: 'Create Event',
            url: 'https://organizer.eventfullsunday.co.uk'
        },
        {
            text: 'Join Us',
            url: '/register'
        }
    ];

    const settings = {
        dots: true,
        infinite: true,         // Auto-loop
        speed: 1000,            // Transition speed
        slidesToShow: 1,        // One image at a time
        slidesToScroll: 1,
        autoplay: true,         // Enables auto-sliding
        autoplaySpeed: 3000,    // Slide every 3 seconds
        centerMode: true,       // Centers the image
        centerPadding: '8%',  // Padding on both sides to adjust width
        responsive: [
          {
            breakpoint: 768,    // For mobile screens
            settings: {
              centerPadding: '0',  // Remove padding on small screens
            }
          }
        ]
      };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await apiClient.get("api/image-upload/");
                // Home IP test
                // const response = await axios.get("http://192.168.1.248:8000/api/image-upload/");

                console.log(response);
                if(response.data.length > 0) {
                  const bannerData = response.data.sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at));
                  console.log(bannerData);
                  setImages([
                    bannerData[0]?.slider_1 ?? '', 
                    bannerData[0]?.slider_2 ?? '', 
                    bannerData[0]?.slider_3 ?? '', 
                    bannerData[0]?.slider_4 ?? '', 
                    bannerData[0]?.slider_5 ?? ''
                  ].filter((slider) => slider !== ''));
                  setEventId(bannerData[0].event_id);
                } else {
                  setImages([]);
                }
                setLoading(false);
            } catch(error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    // Loading state
    if (loading) {
        return <Shimmer width="100%" height="20rem" borderRadius="rounded" />;
    }

  return (
    <>
    {images.length > 0 && <div className='w-full h-72 my-2 overflow-hidden'>
      <Slider {...settings}>
        {images && images.map((image, index) => (
            <div key={index} className="h-72 flex justify-center items-center">
                <a href={ctaButtons[index].url}>
                    <div 
                    className="relative w-full h-full rounded-lg flex justify-center items-center overflow-hidden"
                    >
                      <img src={image} alt={`Banner ${index + 1}`} className="object-fill w-full h-full rounded-lg" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                      <button className="absolute bottom-10 left-8 max-w-[80%] capitalize h-10 bg-sunday_tertiary rounded-full cursor-pointer px-4 py-2 text-base tracking-wide text-white hover:bg-sunday_secondary transition-all max-w-full overflow-hidden whitespace-nowrap text-ellipsis">{ctaButtons[index].text}</button>
                    </div>
                </a>
            </div>
        ))
        }
      </Slider>
    </div>}
    {images.length <= 0 && ''}
    </>
  )
}

export default SlickBanner
