// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom"; // Ensure proper casing
import { GoogleOAuthProvider } from '@react-oauth/google';
import { store, persistor } from "./redux/store"; // Import store and persistor
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_CLIENT_ID}
    >
      <BrowserRouter>
        {/* Wrap <App /> with <PersistGate> */}
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Provider>
);

reportWebVitals();
