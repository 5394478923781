// src/components/pages/readyfortickets/step0-readyfortickets.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../page/Navbar/Navbar";
import Footer from "../../page/Footer";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EventGetItems } from "../../redux/auth/action/eventDetailAction";
import ProgressTimeline from "./ProgressTimeline";
import TicketSelection from "./step1-TicketSelection";
import ReviewAndPayment from "./step2-ReviewAndPayment";
import Confirmation from "./step3-Confirmation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { fetchTicketByEventId } from "../../redux/auth/action/fetchTicketActions";
import { setSelectedTicket, resetSelectedTicket, setSelectedQuantity } from "../../redux/auth/action/selectedTicketActions";
import ReactGA from "react-ga4";

const stripePromise = loadStripe("pk_test_51PokKeAYVDhgEidXzPKzYHEOhoHUHR1z1zYz3EM32kUtYLC9jU5LyvRBlaDwpx1F8L9LlS13lZPpPoGz13dr0ZFf00190UEQ7u");

const ReadyforTickets = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [eventDetails, setEventDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // Access Redux state
  const { loading, data, error } = useSelector((state) => state.eventItems);
  const selectedTicket = useSelector((state) => state.selectedTicket.selectedTicket);
  const ticketQuantity = useSelector((state) => state.selectedTicket.ticketQuantity);

  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
      dispatch(fetchTicketByEventId(id));
    }

    // Cleanup on unmount
    return () => {
      dispatch(resetSelectedTicket());
    };
  }, [dispatch, id]);

  useEffect(() => {
    // Set eventDetails based on selectedTicket and fetched event data
    if (selectedTicket && data) {
      const details = {
        event: data.event_id, // Ensure this matches your API response
        event_name: data.event_name,
        venue: data.venue_name,
        address: data.venue_address,
        country: data.event_origin_country,
        post_code: data.venue_post_code,
        time: `${formatTime(data.start_time)} - ${formatTime(data.end_time)}`,
        ticket: selectedTicket.id,
        price: parseFloat(selectedTicket.price),
        ticket_quantity: ticketQuantity,
        sub_total: ticketQuantity * parseFloat(selectedTicket.price),
        total: ticketQuantity * parseFloat(selectedTicket.price),
      };
      setEventDetails(details);
    }
  }, [selectedTicket, data, ticketQuantity]);

  /**
   * Formats a time string from "HH:MM:SS" to "HH:MM"
   * @param {string} time - Time string in "HH:MM:SS" format
   * @returns {string} - Formatted time string
   */
  const formatTime = (time) => {
    if (time) {
      return time.split(':').slice(0, 2).join(':');
    }
    return "N/A";
  };

  /**
   * Initiates ticket purchase by setting event details and navigating to the appropriate step
   * Also triggers Google Analytics event
   * @param {Object} details - Event and ticket details
   */
  const handleGetTickets = (details) => {
    console.log("[DEBUG] details: ", details);

    // Remove the following lines:
    // dispatch(setSelectedTicket(selectedTicket)); // Already managed via TicketSection
    // setTicketQuantity(details.ticket_quantity); // Managed via Redux

    setEventDetails(details);

    if (details.price === 0) {
      // Skip to confirmation step for free tickets
      setCurrentStep(3);
    } else {
      // Proceed to payment step
      setCurrentStep(2);
    }

    // Trigger Google Analytics event
    if (details.event_name) {
      ReactGA.event('begin_checkout', {
        currency: 'GBP',
        value: parseFloat(details.total) || 0,
        items: [
          {
            item_id: selectedTicket.id,
            item_name: details.event_name,
            price: parseFloat(details.price),
            quantity: details.ticket_quantity,
          },
        ],
      });

      console.log(`GA Event Sent: Begin Checkout for "${details.event_name}"`);
    } else {
      console.warn("[GA WARNING] details.event_name is undefined. GA event not sent.");
    }
  };

  /**
   * Increments the ticket quantity
   */
  const handleIncrement = () => {
    dispatch(setSelectedQuantity(ticketQuantity + 1));
  };

  /**
   * Decrements the ticket quantity, ensuring it doesn't go below 1
   */
  const handleDecrement = () => {
    if (ticketQuantity > 1) {
      dispatch(setSelectedQuantity(ticketQuantity - 1));
    }
  };

  /**
   * Confirms payment and moves to the confirmation step
   */
  const handleConfirmPayment = () => {
    setCurrentStep(3);
  };

  /**
   * Handles step changes, allowing only backward movement
   * @param {number} step - The step to navigate to
   */
  const handleStepChange = (step) => {
    if (step < currentStep) {
      setCurrentStep(step); // Only allow moving to a previous step
    }
  };

  // Render loading or error states
  if (loading) return <p>Loading...</p>;
  if (error) return <p>There was an error. Please try again later.</p>;

  // If no ticket is selected, prompt the user to select one
  if (!selectedTicket) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex flex-col items-center flex-grow px-4">
          <div className="w-full max-w-4xl flex flex-col gap-6 py-8">
            <p className="text-red-500">No ticket selected. Please go back and select a ticket.</p>
            <Link to={`/event/${id}`} className="text-blue-600 underline">Go Back to Event Details</Link>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex flex-col items-center flex-grow px-4">
        <div className="w-full max-w-4xl flex flex-col gap-6 py-8">
          {/* Progress Timeline */}
          <ProgressTimeline currentStep={currentStep} handleStepChange={handleStepChange} />

          {/* Step 1: Ticket Selection */}
          {currentStep === 1 && (
            <TicketSelection
              handleGetTickets={handleGetTickets}
            />
          )}

          {/* Step 2: Review and Payment */}
          {currentStep === 2 && selectedTicket.price > 0 && (
            <Elements stripe={stripePromise}>
              <ReviewAndPayment
                eventDetails={eventDetails}
                handleConfirmPayment={handleConfirmPayment}
              />
            </Elements>
          )}

          {/* Step 3: Confirmation */}
          {currentStep === 3 && (
            <Confirmation eventDetails={eventDetails} />
          )}

          {/* Footer Links on Confirmation Step */}
          <div className="flex flex-col items-center mt-6">
            {currentStep === 3 && (
              <>
                <Link to={{ pathname: "/dashboard", search: "?section=purchase-history" }}>
                  <p className="text-blue-600 font-poppins text-sm md:text-base underline mb-4">
                    Go to your Tickets
                  </p>
                </Link>
                <p className="font-poppins text-sm md:text-base text-white bg-red-500 p-3 rounded-lg text-center max-w-2xl">
                  If you have any questions, please contact: sales@eventfullsundays.co.uk
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReadyforTickets;
